<template>
  <v-container class="pt-9">
    <v-row class="mb-6" no-gutters align="center">
      <v-col cols="auto">
        <v-btn-toggle
          v-model="showPermission"
          class="permission-btn-group"
          group
          dense
          mandatory
          @change="fetchTeams(false)"
        >
          <v-btn class="ma-0" value="owner" text>
            {{ $t('own') }}
          </v-btn>
          <v-btn class="ma-0" value="member" text>
            {{ $t('involved') }}
          </v-btn>
        </v-btn-toggle>
      </v-col>

      <v-spacer />

      <v-col cols="auto">
        <TeamAdder v-model="dialogTeam" @successAdded="fetchTeams">
          <template v-slot:activator="{ on, attr }">
            <v-btn
              class="btn-add-team"
              color="primary"
              v-bind="attr"
              :disabled="disableActionsBtn"
              rounded
              v-on="on"
              @click="addTeam"
            >
              {{ $t('page.teams.add_team_title') }}
            </v-btn>
          </template>
        </TeamAdder>
      </v-col>
    </v-row>

    <IssueCard
      v-if="error"
      class="mt-13"
      :message="$t('api_errors.data_error_refresh_page')"
      error
    />

    <v-card v-else outlined>
      <v-data-table
        class="team-table"
        :headers="tableHeaders"
        :items="showTeams"
        :items-per-page="-1"
        :search="search"
        :item-class="() => 'team-tr'"
        :loading="isDeleting || isLoadingTeams"
        :no-data-text="noDataText"
        :loading-text="`${$t('loading')}...`"
        fixed-header
        border
        hide-default-footer
        style="width: 100%;"
      >
        <!-- slot: item.actions -->
        <template v-if="isOwner" v-slot:[`item.actions`]="{ item: team }">
          <v-btn
            color="primary"
            icon
            :disabled="disableActionsBtn"
            @click="editTeam(team)"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
            color="primary"
            icon
            :disabled="disableActionsBtn"
            @click="deleteTeam(team)"
          >
            <v-icon small>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import IssueCard from '@/components/IssueCard'
import TeamAdder from '@/components/TeamAdder/TeamAdder'

import { mapState, mapGetters } from 'vuex'

const tableHeaders = (vm) => {
  const headers = [
    {
      text: vm.$t('team_name'),
      value: 'name',
      width: '25%'
    },
    {
      text: vm.$t('team_size'),
      value: 'memberCount',
      width: '25%'
    },
    {
      text: vm.$t('action'),
      value: 'actions',
      width: '25%'
    }
  ]

  if (!vm.isOwner) {
    return headers.filter((header) => header.value !== 'actions')
  }

  return headers
}

export default {
  name: 'Teams',

  components: {
    IssueCard,
    TeamAdder
  },

  data: () => ({
    dialogTeam: false,
    search: '',
    isDeleting: false,
    error: false,
    showPermission: 'owner'
  }),

  computed: {
    ...mapState({
      teams: (state) => state.teams.teams,
      isLoadingTeams: (state) => state.teams.isLoadingTeams
    }),
    ...mapGetters({
      ownerTeams: 'teams/ownerTeams',
      memberTeams: 'teams/memberTeams'
    }),
    tableHeaders,

    showTeams() {
      if (this.showPermission === 'member') return this.memberTeams

      return this.ownerTeams
    },
    isOwner() {
      return this.showPermission === 'owner'
    },
    disableActionsBtn() {
      return this.isDeleting || this.isLoadingTeams
    },
    noDataText() {
      return this.showPermission === 'owner'
        ? this.$t('page.teams.no_data_and_create_team')
        : this.$t('page.teams.no_team_joined')
    }
  },

  created() {
    this.fetchTeams().then(() => {
      if (this.ownerTeams.length) {
        // 顯示owner為主
        this.showPermission = 'owner'
      } else if (this.memberTeams.length) {
        // 沒有owner但有member則顯示member
        this.showPermission = 'member'
      }
    })
  },

  methods: {
    fetchTeams(forceUpdate = true) {
      if (!forceUpdate && this.isLoadingTeams) return

      this.error = false
      return this.$store.dispatch('teams/fetchTeams').catch(() => {
        this.error = true
      })
    },
    addTeam() {
      this.$store.dispatch('dashboards/form/init')
    },
    editTeam(team) {
      this.$store.dispatch('teams/form/edit', { team }).then(() => {
        this.dialogTeam = true
      })
    },
    deleteTeam(team) {
      const confirm = window.confirm(
        this.$t('confirm_delete_item', {
          name: team.name
        })
      )

      if (!confirm) return

      this.isDeleting = true
      this.$store
        .dispatch('teams/deleteTeam', { team })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_deleted')
          })
          return this.fetchTeams()
        })
        .finally(() => {
          this.isDeleting = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-btn-toggle.permission-btn-group {
  .v-btn.v-item--active {
    color: $color-primary;
  }
}

.v-btn:not(.v-btn--round).v-size--default.btn-add-team {
  padding: 20px 2.25rem;
  font-size: 1.25rem;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  letter-spacing: normal;
}

.team-table {
  ::v-deep .v-data-table__wrapper {
    max-height: calc(100vh - 190px);
  }

  ::v-deep .v-data-table-header {
    tr th {
      font-size: 1.5rem;
      font-weight: bold;
      color: #343843;
    }
  }

  ::v-deep .team-tr {
    td {
      overflow: hidden;
      max-width: 120px;
      font-size: 1.125rem;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
